/* ==========================================================================
   Global Icons - Mixins
   ========================================================================== */

@import 'icons';

/* Mixin - Font Icon Base
   ========================================================================== */
/*
 * Common styles used by all icons in HTML and CSS
 */

@mixin font-icon-base() {
  font-family: 'icons';
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Mixin - Font Icon Replace
   ========================================================================== */
/*
 * Replace text with a font icon
 */

@mixin font-icon-replace($pseudo) {
  position: relative;
  #{$rdirection}: 9999px;
  &:#{$pseudo} {
    position: absolute;
    height: 100%;
    text-align: center;
    top: 0;
    #{$rdirection}: -9999px;
  }
}

/* Mixin - Icon
   ========================================================================== */
/*
 * @params
 *  $icon
 *  $pseudo
 *  $mq
 *  $text-replace
 *  $size
 *  $color
 */

@mixin icon(
  $icon: $icon,
  $pseudo: before,
  $mq: false,
  $text-replace: false,
  $size: inherit,
  $color: inherit
) {
  &:#{$pseudo} {
    content: map-get($icons, $icon);
    font-size: $size;
    // If in a media query, include instead of extend
    @if $mq == true {
      @include font-icon-base();
      @include unset-underline-links($extend: false);
      @include set-link-colors(
        $link-normal: $color,
        $link-hover: $color,
        $link-active: $color,
        $link-visited: $color,
        $link-focus: $color,
        $extend: false
      );
    } @else {
      @include font-icon-base();
      @include unset-underline-links($extend: false);
      @include set-link-colors(
        $link-normal: $color,
        $link-hover: $color,
        $link-active: $color,
        $link-visited: $color,
        $link-focus: $color,
        $extend: true
      );
    }
  }
  // Replace text with icon, like classic sprites
  @if $text-replace {
    @include font-icon-replace($pseudo);
  }
}

/* Mixin - Corner Triangle
   ========================================================================== */

@mixin corner-triangle() {
  float: #{$rdirection};
  display: inline-block;
  .csstransforms & {
    @include transform(rotate(135deg));
    @include arrow($color: black, $width: 18px, $height: 9px, $direction: up);
    margin-top: 6px;
  }
  .no-csstransforms & {
    background: image-url('fallbacks/corner-triangle.png') no-repeat;
    width: 12px;
    height: 12px;
    margin-#{$rdirection}: 6px;
  }
}

@mixin center-triangle() {
  float: #{$rdirection};
  display: inline-block;
  .csstransforms & {
    @include transform(rotate(90deg));
    @include arrow($color: black, $width: 14px, $height: 7px, $direction: up);
    margin: 6px 5px 0px 20px;
  }
  .no-csstransforms & {
    background: image-url('fallbacks/corner-triangle.png') no-repeat;
    width: 12px;
    height: 12px;
    margin-#{$rdirection}: 6px;
  }
}

@mixin thick-close($color: $white) {
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 15px;
  overflow: hidden;
  top: 5px;
  right: 5px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: $color;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
/* Mixin - Checkmark
   ========================================================================== */
@mixin checkmark($width: 3px, $height: 6px, $border-width: 2px, $color: $color--black) {
  display: inline-block;
  &:after {
    content: '';
    display: block;
    width: $width;
    height: $height;
    border: solid $color;
    border-width: 0 $border-width $border-width 0;
    transform: rotate(45deg);
  }
}
