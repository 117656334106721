// This file is generated by `gulp icons`, do not modify

$icons: (
  account: '\E001',
  arrow--down: '\E002',
  arrow--left-large: '\E003',
  arrow--left-medium: '\E004',
  arrow--left: '\E005',
  arrow--right-large: '\E006',
  arrow--right-medium: '\E007',
  arrow--right: '\E008',
  arrow--up-medium: '\E009',
  arrow--up: '\E00A',
  bars: '\E00B',
  caret-down: '\E00C',
  caret-right: '\E00D',
  caret-up: '\E00E',
  cart: '\E00F',
  check: '\E010',
  envelope: '\E011',
  facebook: '\E012',
  geolocation: '\E013',
  heart-o: '\E014',
  heart: '\E015',
  instagram: '\E016',
  location-pin: '\E017',
  map-marker: '\E018',
  pinterest: '\E019',
  play-circle: '\E01A',
  play: '\E01B',
  remove: '\E01C',
  search: '\E01D',
  shopping-bag: '\E01E',
  tool_tips_shade_finder: '\E01F',
  tumblr: '\E020',
  twitter: '\E021',
  youtube: '\E022'
);
